import React from 'react'

const Contact = () => {
  return (
    <div className='PageTitle'>
      <h1>
        Future Contact me page for Martin101TO
      </h1>
    </div>
  )
}

export default Contact
