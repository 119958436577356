import React from 'react'

const Blog = () => {
  return (
    <div id="blog-page">
      <div className='PageTitle'>
        Posts
      </div>
      <div id="blog-posts">
      </div>
    </div>
  )
}

export default Blog
