import React from 'react'

const Toolbox = () => {
  return (
    <div className='PageTitle'>
      <h1>
        Future Toolbox page for Martin101TO
      </h1>
    </div>
  )
}

export default Toolbox
